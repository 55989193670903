@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.article>h2 {
  color: #52B1D2;
  text-transform: uppercase;
  font-weight: 600;
}

.article>h1 {
  color: #111827;
  font-size: xx-large;
}

.article>p {
  color: #6B7280;
  font-size: 20px;
}

.article>ul {
  background-image: url('./assets/CheckCircle.svg');
  background-repeat: no-repeat;
  line-height: 30px;
  padding-left: 40px;
  margin-top: auto;
  margin-bottom: auto;
}

.article>p>a {
  background-image: url('./assets/PaperClip.svg');
  background-repeat: no-repeat;
  line-height: 30px;
  padding-left: 40px;
  font-weight: 600;
  text-decoration: underline;
}

;